// Variables
@import '@voteshield/styles/src/styles/settings/variables';
@import '@voteshield/styles/src/styles/tools/mixins-breakpoints';

.triple-layout {
  display: flex;
  margin-bottom: 2em;

  @include media-breakpoint-up('sm') {
    display: block;

    i {
      display: block;
      font-size: 4em;
      margin: 0;
      padding: 1.25em 0 0;
      width: 5rem;
    }
  }

  @include media-breakpoint-up('md') {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: $space-unit * 15;
  }

  i {
    display: block;
    font-size: 5em;
    margin: 0 1rem 0 0;
    padding: 1.25rem 0 0;
    width: 5rem;
  }
}

section.values {
  padding-bottom: 1rem;

  .heading-copy {
    margin-bottom: 1em;
  }

  .triple-layout {
    text-align: center;
  }

  i {
    margin: 0 auto;
  }
}

section.public-trust {
  padding-top: 1rem;
}
