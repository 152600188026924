// Variables
@import '@voteshield/styles/src/styles/settings/variables';

.testimonials {
  .testimonial {
    margin-bottom: $space-unit * 4;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .logo-decoration {
    margin-bottom: 3em;
    margin-left: 0;
    margin-right: 4em;
  }

  .logo-content {
    background-position: center center;
    background-size: cover;
    height: 7em;
    width: 7em;
  }

  blockquote {
    font-size: 1.65em;

    cite {
      font-size: 0.85em;
      font-style: normal;
      font-weight: $font-weight-bold;
    }
  }
}
