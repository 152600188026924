/**
 * Legal agreement banner styles.
 */

// Variables
@import '@voteshield/styles/src/styles/settings/variables';

.legal-agreement-banner {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: $space-unit * 1 $space-unit * 0.5;

  p {
    margin-bottom: 0;
    margin-right: $space-unit * 1;
    vertical-align: baseline;
  }

  .button {
    margin-bottom: 0;
    margin-right: $space-unit * 0.5;
    vertical-align: baseline;
  }

  .legal-agreement-action {
    &::before {
      content: '';
      margin: 0;
    }
  }
}
