// Variables
@import '@voteshield/styles/src/styles/settings/variables';

.publications {
  .publication {
    margin-bottom: 3rem;
    padding: 0 2rem;
  }

  a {
    color: $color-text;
    text-decoration: none;

    i {
      color: $color-blue;
    }
  }
}
