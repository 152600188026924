// Variables
@import '@voteshield/styles/src/styles/settings/variables';

$skew: 7;

.landing-intro {
  .heading-copy {
    font-size: 1.2em;
    margin: 0 0 $space-unit * 2 0;
    padding: 0;
  }

  .heading-sub-copy {
    font-size: $font-size-smaller;
    margin-left: auto;
    margin-right: auto;
    max-width: 18rem;
  }

  .color-inverse {
    transform: translateX($skew * 0.1%);

    &::after {
      background: inherit;
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: $skew * -1.2%;
      position: absolute;
      top: 0;
      transform: skewX($skew * -1deg);
      transform-origin: 0;
      width: 50%;
      z-index: -1;
    }

    .button {
      appearance: none;
      // The specifity for the default .color-inverse button seems to be
      // odd, where it doesn't fully apply, and has different behaviors
      // in different browsers.  So, being more specific here.
      // And Safari's "appearance: button" causes issues so we have to reset.
      border-color: $color-white;
    }

    small.error {
      color: $color-error;
    }
  }

  .login-form-wrapper {
    max-width: 90%;
    min-width: 50%;

    form {
      margin: 0;
    }

    button {
      margin-bottom: 0;
    }
  }

  .intro-logged-in {
    margin-bottom: 0;

    .button {
      margin-bottom: $space-unit * 0.5;
    }

    .intro-logged-in-callout {
      display: block;
      margin-bottom: $space-unit * 2;
    }

    .intro-logged-in-callout:last-child {
      margin-bottom: 0;
    }
  }
}
