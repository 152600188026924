/**
 * Styles for landing page content
 */

// Sass modules
@use 'sass:math';

// Variables
@import '@voteshield/styles/src/styles/settings/variables';
@import '@voteshield/styles/src/styles/tools/mixins-breakpoints';

.landing-content {
  h1 {
    line-height: 1.2;
  }

  section {
    padding-bottom: $space-unit * 4;
    padding-top: $space-unit * 2;
  }

  .heading-copy {
    font-size: math.div(18, 14) * 1rem;
    font-weight: $font-weight-normal;
    line-height: $line-height * 0.85;
    margin: 0;
    margin-bottom: 2em;
    padding: 0;

    @include media-breakpoint-up('md') {
      margin-bottom: 4em;
    }
  }

  .callout-bg {
    background-color: $color-gray-light;
    margin-bottom: 2em;
    padding-bottom: 2em;
    padding-top: 2em;

    h1 {
      font-weight: $font-weight-light;
    }

    .heading-copy {
      margin-bottom: 2em;
    }
  }

  .title-wrapper {
    max-width: 90%;
    min-width: 60%;
  }

  .page-title {
    line-height: 0.5;
    margin: 0;
    padding: 0;
    white-space: nowrap;

    i {
      display: inline-block;
      height: 0.9em;
      margin-top: 0.15em;
      vertical-align: top;
      width: 0.9em;
    }
  }
}
