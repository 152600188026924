/**
 * Styles for main header component
 */

// Get variables
@import '@voteshield/styles/src/styles/settings/variables';

.main-header {
  background-color: $color-foreground;

  nav {
    padding-left: 0;
    padding-right: 0;
  }
}
