/**
 * Password validator styles
 */

// Variables
@import '@voteshield/styles/src/styles/settings/variables';

.password-requirements {
  ul li.invalid {
    color: $color-error;
  }
}
