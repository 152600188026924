// Variables
@import '@voteshield/styles/src/styles/settings/variables';

section.in-the-news {
  padding-bottom: 0;
}

.in-the-news {
  .news-article {
    margin-bottom: 4rem;
    text-align: center;

    .news-article-image {
      display: flex;
      height: 8rem;

      a {
        align-self: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 20rem;
      }

      img {
        max-height: 8rem;
      }
    }
  }

  .news-article-link {
    color: $color-text;
    display: block;
    font-size: 1.15em;
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    text-transform: none;
  }

  .news-article-center {
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 875px;
    text-align: center;

    .news-article-image-link {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 20rem;
    }

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-height: 8rem;
      max-width: 100%;
    }
  }
}
