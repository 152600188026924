/**
 * Styles for feedback page link
 */

// Variables
@import '@voteshield/styles/src/styles/settings/variables';
@import '@voteshield/styles/src/styles/tools/mixins-transitions';

.feedback-page-link {
  bottom: 0;
  padding: $space-unit;
  position: fixed;
  right: 0;
  z-index: 99999;

  a[role='button'],
  button {
    box-shadow: $shadow-default;
    margin: 0;
    min-width: auto;

    i {
      padding-right: 0 !important;
    }

    .inner-button-text {
      @include transition(
        padding-right $animation-length-short,
        opacity $animation-length-short,
        max-width $animation-length-default
      );

      display: inline-block;
      max-width: 0.1px;
      opacity: 0;
    }

    &:hover,
    &:focus {
      i {
        padding-right: $space-unit * 0.5 !important;
      }

      .inner-button-text {
        max-width: $space-unit * 6;
        opacity: 1;
      }
    }
  }
}
